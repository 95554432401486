/*
 * SCSS Entrypoint
 * - Override Bootstrap variables
**/

// Bootstrap: https://getbootstrap.com/docs/4.2/getting-started/theming/#theme-colors
// React Bootstrap: https://react-bootstrap.github.io/getting-started/introduction/#customize-bootstrap
// These (and other variables) can all be overridden:;
$theme-colors: (
  "primary": #73b159,
  "offwhite": #f8f9fa,
  "grey": #e4e4e4,
  // Primary
  // Secondary
  // Success
  // Danger
  // Warning
  // Info
  // Light
  // Dark,,,,,,,,,,,,
);

@import "~bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,700&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .jumbotron {
    margin-bottom: 0px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
h4 {
  font-size: 34px;
  text-transform: uppercase;
}
p {
  font-family: "Lora", Georgia, "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 400;
}
img {
  border-radius: 25px;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
